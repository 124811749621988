import React, { useMemo, useCallback } from "react";
import _get from "lodash/get";
import map from "lodash/map";
import each from "lodash/each";
import { QUESTIONS, DELETE_QUESTION } from "./gqlQuestions";
import { TOPICS_FOR_SELECT } from "../topics/gqlTopics";
import { useQuery, useMutation } from "@apollo/client";
import CardWithTitle from "../../components/Layouts/cardWithTitle";
import { GenericTableGenerator } from "../../components/table";
import { useToasts } from "react-toast-notifications";
import { TableCell, Chip, Avatar } from "@material-ui/core";
import { colors } from "../../globalTheme";

const choicesMap = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

const QuestionList = () => {
  const { data, refetch } = useQuery(QUESTIONS, {
    pollInterval: 10000,
  });
  const { data: topicsForSelect } = useQuery(TOPICS_FOR_SELECT);
  const { addToast } = useToasts();
  const [deleteQuestion] = useMutation(DELETE_QUESTION);

  const questions = useMemo(() => _get(data, "data", {}), [data]);

  const handleOnDelete = useCallback(
    (id) => {
      deleteQuestion({ variables: { id } })
        .then((res) => {
          const name = _get(res, "data.data.name");
          if (name) {
            addToast(`${name} deleted successfully!`, {
              appearance: "info",
            });
          }
        })
        .catch((err) => console.log("err", err))
        .finally(() => refetch());
    },
    [addToast, deleteQuestion, refetch]
  );

  const getTopicLabel = useCallback(
    (id) => {
      if (topicsForSelect && topicsForSelect.data) {
        let tmp = "";
        each(topicsForSelect.data, (topic) => {
          if (topic.value === id) tmp = topic.label;
        });
        return tmp;
      } else {
        return id;
      }
    },
    [topicsForSelect]
  );

  console.log("questions", questions);

  console.log("topicsForSelect", topicsForSelect);

  return (
    <CardWithTitle title="Question List" noPadding>
      <GenericTableGenerator
        dataObj={questions}
        onDelete={handleOnDelete}
        cellHidden={["answer"]}
        idOverrideText="Open"
        cellRenderByKey={{
          choices: (val, row) => {
            return (
              <TableCell key={val}>
                {map(val, (item, key) => (
                  <Chip
                    key={`${key}${item}`}
                    label={item}
                    className="mb-1"
                    variant="outlined"
                    size="small"
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor:
                            key === row.answer
                              ? colors.primaryThemeColor
                              : colors.lightViolet,
                        }}
                      >
                        {choicesMap[key]}
                      </Avatar>
                    }
                  />
                ))}
              </TableCell>
            );
          },
          topic: (val) => <TableCell key={val}>{getTopicLabel(val)}</TableCell>,
        }}
      />
    </CardWithTitle>
  );
};

export default QuestionList;

import React from "react";
import _get from "lodash/get";
import _map from "lodash/map";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { TOPIC } from "./gqlTopics";

const TopicView = ({ id }) => {
  const { data } = useQuery(TOPIC, {
    variables: { id },
  });

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <Paper elevation={0} square>
          <Table>
            <TableBody>
              {}
              {_map(_get(data, "data"), (item, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography
                        variant="caption"
                        className="text-uppercase font-weight-bold"
                      >
                        {key}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{item}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TopicView;

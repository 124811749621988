import React from "react";
import _map from "lodash/map";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import QuestionIcon from "@material-ui/icons/EmojiSymbolsOutlined";
import TopicIcon from "@material-ui/icons/NoteOutlined";
import { urlPaths } from "../../routes";
import { useRouter } from "../../components/hooks/useRouter";
import classNames from "classnames";

export const MainListItems = ({ classes }) => {
  const { push, basePathname } = useRouter();
  const items = [
    {
      text: "Questions",
      icon: <QuestionIcon fontSize="small" />,
      path: urlPaths.questions,
    },
    {
      text: "Topics",
      icon: <TopicIcon fontSize="small" />,
      path: urlPaths.topics,
    },
  ];
  return (
    <div>
      {_map(items, ({ text, icon, path }, key) => {
        return (
          <ListItem
            key={`mm-${key}`}
            button
            onClick={() => push(path)}
            className={classNames(basePathname === path && classes.activeMenu)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        );
      })}
    </div>
  );
};

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);

import React, { useMemo } from "react";
import AppBar from "@material-ui/core/AppBar";
import _get from "lodash/get";
import _defaultTo from "lodash/defaultTo";
// import Badge from '@material-ui/core/Badge';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from "clsx";
import Footer from "../../components/Layouts/footer";
import { MainListItems, secondaryListItems } from "./sidebarItems";
import { useAuth } from "../../useAuth";
import { useStyles } from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingComponent from "../../components/LoadingComponent";
import { APP_NAME } from "../../utils/constants";
import { useRouter } from "../../components/hooks/useRouter";
import { pageTitles, pageNavigation } from "../../routes";
import ToolbarTitle from "./toolbarTitle";
import localStorage from "local-storage";
import { Chip } from "@material-ui/core";

const AppBarWithSidebar = ({ children }) => {
  const { isAuthenticated, loading, signOut, user } = useAuth();
  const { basePathname } = useRouter();
  const classes = useStyles();

  const [open, setOpen] = React.useState(
    _defaultTo(localStorage("drawerOpen"), true)
  );
  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage("drawerOpen", true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    localStorage("drawerOpen", false);
  };

  const title = useMemo(
    () =>
      _defaultTo(
        _get(pageNavigation, basePathname),
        _get(pageTitles, basePathname)
      ),
    [basePathname]
  );

  if (!loading && !isAuthenticated) {
    return children;
  }

  return (
    <div className={classes.root}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <AppBar
            elevation={0}
            color="inherit"
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar} variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <ToolbarTitle title={title} />
              {user.email && (
                <>
                  <Typography className="mr-1">{user.email}</Typography>
                  <Chip
                    label={user.emailVerified ? "Verified" : "Unverified"}
                    style={{ height: "1.25rem", fontSize: "0.75rem" }}
                  />
                </>
              )}
              <IconButton>
                {/* <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}
                <Tooltip title="Logout">
                  <LogoutIcon onClick={signOut} />
                </Tooltip>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <Toolbar className={classes.toolbarIcon} variant="dense">
              <Typography
                variant="subtitle2"
                className="ml-1 w-100 text-uppercase text-bold font-weight-bold"
              >
                {APP_NAME}
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <List className="pt-0">
              <MainListItems classes={classes} />
            </List>
            <Divider />
            <List>{secondaryListItems}</List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth={false} className={classes.container}>
              <div className="main-view">{children}</div>
              <Box pt={4}>
                <Footer />
              </Box>
            </Container>
          </main>
        </>
      )}
    </div>
  );
};

export default AppBarWithSidebar;

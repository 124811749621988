import React from "react";
import { useRouter } from "../../components/hooks/useRouter";
import QuestionList from "./QuestionList";
import QuestionView from "./QuestionView";
import QuestionAdd from "./QuestionAdd";
import QuestionAddRaw from "./QuestionAddRaw";

const Topics = () => {
  const {
    match: { params },
  } = useRouter();
  const id = params.id;

  switch (params.page) {
    case "add":
      // if page is add, id passed is topic id
      return <QuestionAdd topicId={id} />;
    case "add-raw":
      return <QuestionAddRaw topicId={id} />;
    case "update":
      return <QuestionAdd id={id} />;
    case "view":
      return <QuestionView id={id} />;
    default:
      return <QuestionList />;
  }
};

export default Topics;

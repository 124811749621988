import React, { useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import _get from "lodash/get";
import _map from "lodash/map";
import _startCase from "lodash/startCase";
import { useToasts } from "react-toast-notifications";
import {
  TOPIC,
  TOPICS_GQL,
  ADD_TOPIC,
  ADD_TOPIC_INPUT,
  UPDATE_TOPIC,
} from "./gqlTopics";
import {
  formGenerator,
  emptyFormGenerator,
  yupSchemaGenerator,
} from "../../utils/graphql";
import { Formik, Form } from "formik";
import { Grid, Button } from "@material-ui/core";
import CardWithTitle from "../../components/Layouts/cardWithTitle";
import CustomTextField from "../../components/FormControls/TextField";
import { useRouter } from "../../components/hooks/useRouter";

const ADD_FORM = formGenerator(ADD_TOPIC_INPUT);
const EMPTY_FORM = emptyFormGenerator(ADD_FORM);
const VALIDATION_SCHEMA = yupSchemaGenerator(ADD_FORM);

/**
 * Component for Add and Update
 */
const TopicAdd = ({ id }) => {
  const { addToast } = useToasts();
  const { push, basePathname } = useRouter();
  const { data: topicData, loading } = useQuery(TOPIC, {
    variables: { id },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [addOrUpdateTopic] = useMutation(id ? UPDATE_TOPIC : ADD_TOPIC, {
    refetchQueries: [{ query: TOPICS_GQL }],
  });

  const initialValues = useMemo(() => {
    const data = _get(topicData, "data");
    if (topicData) {
      return { ...EMPTY_FORM, ...data };
    }
    return EMPTY_FORM;
  }, [topicData]);

  return (
    <Grid container justify="center">
      <Grid item md={8} xs={12}>
        <CardWithTitle title={id ? `Update ${id}` : "Add topic"}>
          {!loading && (
            <Formik
              onSubmit={(variables) => {
                addOrUpdateTopic({ variables })
                  .then((res) => {
                    const name = _get(res, "data.data.name");
                    if (name) {
                      addToast(
                        `${name} successfully ${id ? "updated" : "added"}`,
                        {
                          appearance: "success",
                        }
                      );
                    }
                  })
                  .catch((err) => console.log("err", err))
                  .finally(() => push(basePathname));
              }}
              initialValues={initialValues}
              validationSchema={VALIDATION_SCHEMA}
            >
              {() => {
                return (
                  <Form>
                    {_map(ADD_FORM, (field) => {
                      const { type, name } = field;

                      return (
                        <CustomTextField
                          key={name}
                          label={_startCase(name)}
                          name={name}
                          type={type}
                        />
                      );
                    })}
                    <Grid container justify="center" className="p-3">
                      <Button type="submit" variant="outlined" color="primary">
                        {id ? "Update" : "Submit"}
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </CardWithTitle>
      </Grid>
    </Grid>
  );
};

export default TopicAdd;

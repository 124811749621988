import React from "react";
import map from "lodash/map";
import size from "lodash/size";
import pick from "lodash/pick";
import { Field } from "formik";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  IconButton,
} from "@material-ui/core";
import classNames from "classnames";
import RemoveIcon from "@material-ui/icons/Close";
import {
  useRadioGroupStyle,
  useCustomRadioGroupStyle,
} from "./RadioGroupStyle";

const StyledRadio = (props) => {
  const classes = useRadioGroupStyle();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const CustomRadioGroup = ({
  name,
  label,
  select = [],
  valueParser = (value) => value,
  onRemove = null,
}) => {
  const classes = useCustomRadioGroupStyle();
  if (size(select) < 1) return null;

  return (
    <Field name={name}>
      {({ field, form }) => {
        field.value = valueParser(field.value);

        return (
          <FormControl fullWidth>
            {label && <Typography variant="subtitle2">{label}</Typography>}
            <RadioGroup className="w-100" {...field} {...form}>
              {map(select, (val, index) => {
                return (
                  <FormControlLabel
                    key={field.name}
                    control={<StyledRadio />}
                    value={index}
                    label={
                      <>
                        <span
                          style={{ flex: 1 }}
                          className="d-flex align-items-center"
                        >
                          {val}
                        </span>
                        {onRemove && (
                          <IconButton
                            size="small"
                            onClick={() => {
                              onRemove(index);
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    }
                    classes={pick(classes, ["label"])}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default CustomRadioGroup;

import React from "react";
import { useRouter } from "../../components/hooks/useRouter";
import TopicList from "./TopicList";
import TopicView from "./TopicView";
import TopicAdd from "./TopicAdd";

const Topics = () => {
  const {
    match: { params },
    push,
  } = useRouter();
  const id = params.id;
  
  switch (params.page) {
    case "add":
      return <TopicAdd />;
    case "update":
      return <TopicAdd id={id} />;
    case "view":
      return <TopicView id={id} />;
    default:
      return <TopicList push={push} />;
  }
};

export default Topics;

import React from "react";
import { ApolloProvider } from "@apollo/client";
import 'jsoneditor-react/es/editor.min.css';
import "./App.css";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import AppBarWithSidebar from "./containers/topLevelContainer/AppBarWithSidebar";
import globalTheme from "./globalTheme";
import { ToastProvider } from "react-toast-notifications";
import { AuthProvider } from "./useAuth";
import { client } from "./apollo";

const App = () => {
  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={15000}
      placement="bottom-left"
    >
      <AuthProvider>
        <ApolloProvider client={client}>
          <div className="App">
            <ThemeProvider theme={globalTheme}>
              <AppBarWithSidebar>
                <Routes />
              </AppBarWithSidebar>
            </ThemeProvider>
          </div>
        </ApolloProvider>
      </AuthProvider>
      <div id="snackbar" />
    </ToastProvider>
  );
};

export default App;

let timeoutHide = null;

export const snackbar = (message, type = "") => {
  const x = document.getElementById("snackbar");

  x.innerText = "";
  x.className = "";

  if (timeoutHide) clearTimeout(timeoutHide);

  setTimeout(() => {
    x.innerText = message;
    x.className = `show ${type}`;
  }, 250);

  timeoutHide = setTimeout(() => {
    x.className = x.className.replace("show", "");
  }, 10000);
};

export const snackbarError = (message) => {
  snackbar(message, "error");
};

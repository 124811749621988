import { gql } from "@apollo/client";

export const TOPICS_GQL = gql`
  query Topics {
    topics {
      id
      text
      name
      description
    }
  }
`;

export const TOPICS_FOR_SELECT = gql`
  query TopicsSelection {
    data: topics {
      value: id
      label: name
    }
  }
`;

export const TOPIC = gql`
  query Topic($id: String!) {
    data: topic(id: $id) {
      id
      text
      name
      description
    }
  }
`;

// INPUT used as automatic form fields
export const ADD_TOPIC_INPUT =
  "$text: String, $name: String!, $description: String";

export const ADD_TOPIC = gql`
  mutation AddTopic(${ADD_TOPIC_INPUT}) {
    data: addTopic(text: $text, name: $name, description: $description) {
      id
      text
      name
      description
    }
  }
`;

export const UPDATE_TOPIC = gql`
  mutation UpdateTopic(
    $id: String
    $text: String
    $name: String!
    $description: String
  ) {
    data: updateTopic(
      id: $id
      text: $text
      name: $name
      description: $description
    ) {
      id
      text
      name
      description
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation DeleteTopic($id: String) {
    data: deleteTopic(id: $id) {
      id
      name
    }
  }
`;

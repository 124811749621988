import React from 'react';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { colors } from '../../globalTheme';
import { useRouter } from '../../components/hooks/useRouter';

export const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    borderRadius: 0,
    padding: '4px 2px',
    minWidth: 48,
    margin: '0 12px',
  },
  activeButton: {
    color: colors.primaryThemeColor,
    borderBottom: `solid 3px ${colors.primaryThemeColor}`,
  }
}));

const ToolbarTitle = ({ title }) => {
  const classes = useStyles();
  const { push, pathname } = useRouter();
  return _isArray(title) ?
    <div className="flex-grow-1 align-self-end">
      {_map(title, ({ path, title }, key) =>
        <Button
          key={`tbt-${key}`}
          className={classNames(classes.button, pathname === path && classes.activeButton)}
          onClick={() => push(path)}>
          {title}
        </Button>)}
    </div> :
    <Typography variant="body1" noWrap className="flex-grow-1">{title}</Typography>;
}

export default ToolbarTitle

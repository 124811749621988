import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Logo from "../../assets/logo.png";
import { APP_NAME } from "../../utils/constants";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInSignUpLayout = ({ children, style, noFooter = false }) => {
  const classes = useStyles();
  return (
    <div
      className="d-flex align-items-center"
      style={{ minHeight: "100vh", ...style }}
    >
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <img src={Logo} alt="logo" />
          <Typography component="h1" variant="h5" className="mb-4">
            {APP_NAME}
          </Typography>
          {children}
        </Paper>
        {!noFooter && (
          <Box mt={8}>
            <Footer />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default SignInSignUpLayout;

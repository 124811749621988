import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../globalTheme";

export const useCustomRadioGroupStyle = makeStyles({
  label: {
    display: "flex",
    width: "100%",
  },
});

export const useRadioGroupStyle = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: "1rem",
    height: "1rem",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: colors.primaryThemeColor,
    "input:hover ~ &": {
      backgroundColor: colors.primaryThemeColorDarker,
    },
  },
});

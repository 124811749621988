import React, { useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import get from "lodash/get";
import includes from "lodash/includes";
import toNumber from "lodash/toNumber";
import clone from "lodash/clone";
import { useToasts } from "react-toast-notifications";
import {
  QUESTION,
  QUESTIONS,
  ADD_QUESTION,
  ADD_QUESTION_INPUT,
  UPDATE_QUESTION,
} from "./gqlQuestions";
import { TOPICS_FOR_SELECT } from "../topics/gqlTopics";
import {
  formGenerator,
  emptyFormGenerator,
  yupSchemaGenerator,
} from "../../utils/graphql";
import { Formik, Form, FieldArray } from "formik";
import { Grid, Button } from "@material-ui/core";

import CardWithTitle from "../../components/Layouts/cardWithTitle";
import CustomTextField from "../../components/FormControls/TextField";
import CustomRadioGroup from "../../components/FormControls/RadioGroup";

const ADD_FORM = formGenerator(ADD_QUESTION_INPUT);
const EMPTY_FORM = emptyFormGenerator(ADD_FORM);
const VALIDATION_SCHEMA = yupSchemaGenerator(ADD_FORM);

/**
 * Component for Add and Update
 */
const QuestionAdd = ({ id, topicId = "" }) => {
  const { addToast } = useToasts();
  const { data: topics, loading: topicsLoading } = useQuery(TOPICS_FOR_SELECT);
  const { data: questionData, loading } = useQuery(QUESTION, {
    variables: { id },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [addOrUpdateQuestion] = useMutation(
    id ? UPDATE_QUESTION : ADD_QUESTION,
    {
      refetchQueries: [{ query: QUESTIONS }],
    }
  );

  const initialValues = useMemo(() => {
    const data = get(questionData, "data");
    if (questionData) {
      return { ...EMPTY_FORM, ...data };
    }
    return { ...EMPTY_FORM, answer: 0, topic: topicId };
  }, [questionData, topicId]);

  return (
    <Grid container justify="center">
      <Grid item md={8} xs={12}>
        <CardWithTitle title={id ? `Update ${id}` : "Add question"}>
          {!(loading || topicsLoading) && (
            <Formik
              onSubmit={(values, formikHelpers) => {
                const variables = clone(values);
                // delete tmp_choice
                delete variables.tmp_choice;
                // convert answer to int, formik converts to string
                variables.answer = toNumber(values.answer);

                addOrUpdateQuestion({ variables })
                  .then((res) => {
                    const text = get(res, "data.data.text");
                    if (text) {
                      addToast(
                        `${text} successfully ${id ? "updated" : "added"}`,
                        {
                          appearance: "success",
                        }
                      );
                      formikHelpers.resetForm();
                    }
                  })
                  .catch((err) => console.log("err", err))
              }}
              initialValues={initialValues}
              validationSchema={VALIDATION_SCHEMA}
            >
              {(props) => {
                const choices = get(props, "values.choices", [""]);
                const tmp_choice = get(props, "values.tmp_choice", "");
                const tmp_choice_included = includes(choices, tmp_choice);

                return (
                  <Form>
                    <CustomTextField
                      label="Question"
                      name="text"
                      type="text"
                      autoComplete="off"
                    />
                    <FieldArray
                      name="choices"
                      render={(arrayHelpers) => {
                        const { push, remove } = arrayHelpers;
                        return (
                          <Grid container>
                            <Grid item xs={6} className="pl-1 pr-3 pb-3">
                              <CustomRadioGroup
                                name="answer"
                                label="Select correct answer"
                                select={choices}
                                valueParser={(val) => toNumber(val)}
                                onRemove={(key) => remove(key)}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Grid
                                container
                                alignItems="center"
                                direction="column"
                              >
                                <div>
                                  <CustomTextField
                                    label="New Choice"
                                    name="tmp_choice"
                                    type="text"
                                    autoComplete="off"
                                  />
                                </div>
                                <div style={{ marginTop: "-1rem" }}>
                                  <Button
                                    type="button"
                                    variant="text"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                      if (tmp_choice && !tmp_choice_included) {
                                        push(tmp_choice);
                                        props.setFieldValue("tmp_choice", "");
                                      }
                                    }}
                                    className="mb-3"
                                  >
                                    Add to choice
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }}
                    />
                    <CustomTextField
                      label="Description"
                      name="description"
                      type="text"
                      autoComplete="off"
                    />
                    <CustomTextField
                      label="Explanation"
                      name="explanation"
                      type="text"
                      autoComplete="off"
                      multiline
                    />
                    <CustomTextField
                      label="Topic"
                      name="topic"
                      type="text"
                      autoComplete="off"
                      select={get(topics, "data", [])}
                    />
                    <Grid container justify="center" className="p-3">
                      <Button type="submit" variant="outlined" color="primary">
                        {id ? "Update" : "Submit"}
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </CardWithTitle>
      </Grid>
    </Grid>
  );
};

export default QuestionAdd;

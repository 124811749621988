import { gql } from "@apollo/client";

export const QUESTIONS = gql`
  query Questions {
    data: questions {
      id
      text
      description
      choices
      answer
      explanation
      topic
    }
  }
`;

export const QUESTION = gql`
  query Question($id: String!) {
    data: question(id: $id) {
      id
      text
      description
      choices
      answer
      explanation
      topic
    }
  }
`;

// INPUT used as automatic form fields
export const ADD_QUESTION_INPUT =
  "$text: String!, $description: String, $choices: [String]!, $answer: Int!, $explanation: String, $topic: String";

export const ADD_QUESTION = gql`
  mutation AddQuestion(${ADD_QUESTION_INPUT}) {
    data: addQuestion( text: $text, description: $description, choices: $choices, answer:$answer, explanation:$explanation, topic:$topic ) {
      id
      text
      description
      choices
      answer
      explanation
      topic
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $id: String
    $text: String!
    $description: String
    $choices: [String]!
    $answer: Int!
    $explanation: String
    $topic: String
  ) {
    data: updateQuestion(
      id: $id
      text: $text
      description: $description
      choices: $choices
      answer: $answer
      explanation: $explanation
      topic: $topic
    ) {
      id
      text
      description
      choices
      answer
      explanation
      topic
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: String) {
    data: deleteQuestion(id: $id) {
      id
      text
    }
  }
`;

import * as yup from "yup";
import _get from "lodash/get";
import _split from "lodash/split";
import _map from "lodash/map";
import _each from "lodash/each";
import _replace from "lodash/replace";
import _lowerCase from "lodash/lowerCase";
import _includes from "lodash/includes";

const typeMap = {
  string: "text",
  int: "number",
};
/**
 * Generate form definition from graphql input definition
 *
 * @typedef {Object} Form
 * @property {string} name
 * @property {string} type
 * @property {string} rawType
 * @property {boolean} required
 *
 * @param {String} gqlInputStub - graphql mutation input fragment
 * @returns Form
 */
export const formGenerator = (gqlInputStub) => {
  return _map(_split(gqlInputStub, ","), (item) => {
    const itemNoWhiteSpace = _replace(item, /\s/g, "");
    const valuePair = _split(itemNoWhiteSpace, ":");
    const name = _replace(valuePair[0], "$", "");
    const type = _lowerCase(_replace(valuePair[1], /[^a-zA-Z]/g, ""));
    const isArray =
      _includes(valuePair[1], "[") && _includes(valuePair[1], "]");
    const required = _includes(valuePair[1], "!");

    return {
      name,
      type: _get(typeMap, type, type),
      rawType: type,
      isArray,
      required,
    };
  });
};

/**
 * Create formik compatible initial values
 *
 * @param {Form} form
 */
export const emptyFormGenerator = (form) => {
  let tmp = {};
  _each(form, (i) => (tmp[i.name] = i.isArray ? [] : ""));
  return tmp;
};

/**
 * create equivalent yup validation
 */
const getYupSchema = ({ type: typeRaw, required }) => {
  const type = _lowerCase(typeRaw);

  if (type === "string") {
    if (required) return yup.string().required();
    return yup.string();
  } else if (type === "number") {
    if (required) return yup.number().required();
    return yup.number();
  }
};
/**
 *
 * @param {Form} form
 */
export function yupSchemaGenerator(form) {
  let tmp = {};
  _each(form, (field) => {
    tmp[field.name] = getYupSchema({
      type: field.rawType,
      required: field.required,
    });
  });

  return yup.object(tmp);
}

import React, { useMemo, useCallback } from "react";
import get from "lodash/get";
import size from "lodash/size";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import { TOPICS_GQL, DELETE_TOPIC } from "./gqlTopics";
import { useQuery, useMutation } from "@apollo/client";
import CardWithTitle from "../../components/Layouts/cardWithTitle";
import { GenericTableGenerator } from "../../components/table";
import { useToasts } from "react-toast-notifications";
import { TableCell, Link } from "@material-ui/core";
import { urlPaths } from "../../routes";

const TopicList = ({ push }) => {
  const { data, refetch } = useQuery(TOPICS_GQL, {
    pollInterval: 5000,
  });
  const { addToast } = useToasts();
  const [deleteTopic] = useMutation(DELETE_TOPIC);

  const topics = useMemo(() => {
    let tmp = cloneDeep(get(data, "topics", []));
    if (size(tmp) > 0) {
      // Add question column to topics list
      each(tmp, (row, key) => {
        tmp[key] = { ...tmp[key], question: get(row, "id") };
      });
    }
    return tmp;
  }, [data]);

  const handleOnDelete = useCallback(
    (id) => {
      deleteTopic({ variables: { id } })
        .then((res) => {
          const name = get(res, "data.data.name");
          if (name) {
            addToast(`${name} deleted successfully!`, {
              appearance: "info",
            });
          }
        })
        .catch((err) => console.log("err", err))
        .finally(() => refetch());
    },
    [addToast, deleteTopic, refetch]
  );

  return (
    <CardWithTitle title="Topic List">
      <GenericTableGenerator
        dataObj={topics}
        onDelete={handleOnDelete}
        idOverrideText="Open"
        cellRenderByKey={{
          question: (val) => {
            return (
              <TableCell>
                <Link onClick={() => push(`${urlPaths.questions}/add/${val}`)}>Add</Link>
              </TableCell>
            );
          },
        }}
      />
    </CardWithTitle>
  );
};

export default TopicList;

import React from 'react'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonSubmit = ({ children, className, disabled, loading, style, color = "primary", type = "submit", ...otherProps }) => {
  return (
    <Button
      type={type}
      disabled={disabled || loading}
      variant="contained"
      color={color}
      className={className}
      style={{ color: 'white', fontSize: 14, ...style }}
      {...otherProps}
    >{children} {loading && <CircularProgress className="ml-2" color="inherit" size={14} />}</Button>
  )
}

export default ButtonSubmit

import React, { useState, useEffect, useCallback, createRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import get from "lodash/get";
import clone from "lodash/clone";
import map from "lodash/map";
import { useToasts } from "react-toast-notifications";
import {
  QUESTION,
  QUESTIONS,
  ADD_QUESTION,
  ADD_QUESTION_INPUT,
  UPDATE_QUESTION,
} from "./gqlQuestions";
import { TOPICS_FOR_SELECT } from "../topics/gqlTopics";
import {
  formGenerator,
  emptyFormGenerator,
  yupSchemaGenerator,
} from "../../utils/graphql";
import { Grid, Button, Typography } from "@material-ui/core";
import { JsonEditor } from "jsoneditor-react";
import CardWithTitle from "../../components/Layouts/cardWithTitle";

const ADD_FORM = formGenerator(ADD_QUESTION_INPUT);
const EMPTY_FORM = emptyFormGenerator(ADD_FORM);
const VALIDATION_SCHEMA = yupSchemaGenerator(ADD_FORM);

/**
 * Component for Add and Update
 */
const QuestionAddRaw = ({ id, topicId = "" }) => {
  const { addToast } = useToasts();
  const [value, setValue] = useState({});
  const [newChoice, setNewChoice] = useState("");
  const [errors, setErrors] = useState("");
  const { data: topics, loading: topicsLoading } = useQuery(TOPICS_FOR_SELECT);
  const { data: questionData, loading } = useQuery(QUESTION, {
    variables: { id },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [addOrUpdateQuestion] = useMutation(
    id ? UPDATE_QUESTION : ADD_QUESTION,
    {
      refetchQueries: [{ query: QUESTIONS }],
    }
  );

  const handleSubmit = useCallback(
    (variables) => {
      addOrUpdateQuestion({ variables })
        .then((res) => {
          const text = get(res, "data.data.text");
          if (text) {
            addToast(`${text} successfully ${id ? "updated" : "added"}`, {
              appearance: "success",
            });
            setValue({ ...EMPTY_FORM, topic: topicId });
          }
        })
        .catch((err) => console.log("err", err));
    },
    [addOrUpdateQuestion, addToast, id, topicId, setValue]
  );

  useEffect(() => {
    let data = get(questionData, "data");
    if (questionData) {
      data = { ...EMPTY_FORM, ...data };
    } else {
      data = { ...EMPTY_FORM, answer: 0, topic: topicId };
    }

    setValue(data);
  }, [questionData, topicId]);

  const ref = createRef();

  useEffect(() => {
    // if value is changed programmatically, update json editor data
    if (ref.current) {
      ref.current.jsonEditor.update(value);
    }
  }, [value, ref]);

  return (
    <Grid container justify="center">
      <Grid item md={8} xs={12}>
        <CardWithTitle title={id ? `Update ${id}` : "Add question"}>
          {!(loading || topicsLoading) && (
            <>
              <JsonEditor
                ref={ref}
                value={value}
                onChange={(e) => {
                  console.log("e", e);
                  setValue(e);
                }}
                mode="tree"
              />
              <div className="pt-1 pb-4 d-flex align-items-center justify-content-between">
                <span>
                  <input
                    value={newChoice}
                    name="new_choice"
                    type="text"
                    placeholder="new choice"
                    autoComplete="off"
                    onChange={(e) => setNewChoice(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (newChoice) {
                        const choices = value.choices.concat(newChoice);
                        setValue({ ...value, choices });
                        setNewChoice("");
                      }
                    }}
                  >
                    Add
                  </button>
                </span>
                <span>
                  <label>Topic </label>
                  <select
                    value={get(value, "topic", "")}
                    onChange={(e) => {
                      const topic = get(e, "target.value");
                      setValue({ ...value, topic });
                    }}
                  >
                    {map(get(topics, "data", []), (item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              {errors && (
                <Typography className="pb-4 text-center" variant="subtitle2" color="error">
                  {errors}
                </Typography>
              )}
              <div className="text-center">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const variables = clone(value);

                    VALIDATION_SCHEMA.validate(variables)
                      .then(() => handleSubmit(variables))
                      .catch((err) => setErrors(err.message));
                  }}
                >
                  {id ? "Update" : "Submit"}
                </Button>
              </div>
            </>
          )}
        </CardWithTitle>
      </Grid>
    </Grid>
  );
};

export default QuestionAddRaw;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

const LoadingComponent = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 flex-column">
      <CircularProgress />
      <Typography className="mt-3" variant="body1">Loading</Typography>
    </div>
  )
}

export default LoadingComponent

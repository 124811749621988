import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/login/login";
import Register from "./containers/register/register";
import Topics from "./containers/topics/topics";
import Questions from "./containers/questions/questions";
import { RequireAuth } from "./containers/topLevelContainer/requireAuth";

export const urlPaths = {
  login: "/login",
  register: "/register",
  dashboard: "/dashboard",
  topics: "/topics",
  questions: "/questions",
};

export const urlPathsWithParams = {
  topics: `${urlPaths.topics}/:page(add|view|update)?/:id?`,
  questions: `${urlPaths.questions}/:page(add|view|update|add-raw)?/:id?`,
};

export const pageTitles = {
  [urlPaths.dashboard]: "Dashboard",
};

/**
 * Page navigation for pages that has sub pages
 */
export const pageNavigation = {
	[urlPaths.topics]: [
    {
      title: "Topics",
      path: urlPaths.topics,
    },
    {
      title: "Add topic",
      path: `${urlPaths.topics}/add`,
    },
  ],
  [urlPaths.questions]: [
    {
      title: "Questions",
      path: urlPaths.questions,
    },
    {
      title: "Add question",
      path: `${urlPaths.questions}/add`,
    },
  ],
};

const Routes = () => {
  return (
    <Switch>
      <Route path={urlPaths.login} component={Login} />
      <Route path={urlPaths.register} component={Register} />
      <RequireAuth>
        <Route path={urlPathsWithParams.topics} component={Topics} />
        <Route path={urlPathsWithParams.questions} component={Questions} />
      </RequireAuth>
    </Switch>
  );
};

export default Routes;

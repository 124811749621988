import React, { useMemo, useState, useCallback } from "react";
import _get from "lodash/get";
import _keys from "lodash/keys";
import _map from "lodash/map";
import _each from "lodash/each";
import _isArray from "lodash/isArray";
import _includes from "lodash/includes";
import _first from "lodash/first";
import _startCase from "lodash/startCase";
import _upperCase from "lodash/upperCase";
import _lowerCase from "lodash/lowerCase";
import _join from "lodash/join";
import isFunction from "lodash/isFunction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import { useRouter } from "./hooks/useRouter";

const autoCaps = ["id", "Id"];
const ACTIONS = {
  DELETE: "delete",
};

/**
 * This will return material-ui's table head component
 */
export const GenericTableGenerator = ({
  dataObj,
  maskIdLen = 5,
  onDelete,
  showEdit = true,
  cellRenderByKey = {},
  cellHidden = [],
  idOverrideText = "",
}) => {
  const { push, pathname } = useRouter();
  const [activeId, setActiveId] = useState("");
  const [action, setAction] = useState("");

  const collection = useMemo(() => {
    if (!dataObj) return;

    if (_isArray(dataObj)) {
      return _keys(_first(dataObj));
    } else {
      return _keys(dataObj);
    }
  }, [dataObj]);

  const activeItem = useMemo(() => {
    let active = {};
    _each(dataObj, (item) => {
      if (item.id === activeId) active = item;
    });
    return active;
  }, [activeId, dataObj]);

  const handleClose = useCallback(() => {
    setActiveId("");
    setTimeout(() => {
      setAction("");
    }, 250);
  }, [setActiveId, setAction]);

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {_map(collection, (key) => {
              if (_includes(cellHidden, key)) return null;

              const value = _includes(autoCaps, key)
                ? _upperCase(key)
                : _startCase(key);

              return <TableCell key={key}>{value}</TableCell>;
            })}
            {onDelete && <TableCell padding="none" />}
            {showEdit && <TableCell padding="none" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {_map(dataObj, (row) => (
            <TableRow key={_get(row, "id", _first(row))}>
              {_map(collection, (key) => {
                if (_includes(cellHidden, key)) return null;

                let cellValue = _get(row, key);
                if (maskIdLen && _lowerCase(key) === "id" && cellValue) {
                  cellValue = cellValue.slice(0, maskIdLen);

                  // if id value has text override
                  if (idOverrideText) cellValue = idOverrideText;
                }
                const isLink = _lowerCase(key) === "id";

                if (cellRenderByKey[key] && isFunction(cellRenderByKey[key])) {
                  // cellRenderByKey should return object of function that returns component
                  return cellRenderByKey[key](cellValue, row);
                }

                return (
                  <TableCell
                    key={`${key}-${JSON.stringify(cellValue).slice(0, 5)}`}
                  >
                    {isLink ? (
                      <Link
                        onClick={() =>
                          push(`${pathname}/view/${_get(row, key)}`)
                        }
                      >
                        {cellValue}
                      </Link>
                    ) : _isArray(cellValue) ? (
                      _join(cellValue, "\n")
                    ) : (
                      cellValue
                    )}
                  </TableCell>
                );
              })}
              {onDelete && (
                <TableCell padding="none" className="p-1">
                  <DeleteIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const id = _get(row, "id");
                      setActiveId(id);
                      setAction(ACTIONS.DELETE);
                    }}
                  />
                </TableCell>
              )}
              {showEdit && (
                <TableCell padding="none" className="pr-3">
                  <EditIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const id = _get(row, "id");
                      push(`${pathname}/update/${id}`);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/*
       * Action dialog
       */}
      <Dialog open={!!activeId} onClose={handleClose}>
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          Are you sure you want to {action}?
          <Grid container className="pt-3 pl-5 pr-5" spacing={1}>
            {_map(activeItem, (field, key) => {
              return (
                <React.Fragment key={key}>
                  <Grid item xs={3} className="text-right">
                    <Typography variant="caption" className="font-weight-bold">
                      {_upperCase(key)}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" color="textPrimary">
                      {field}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (action === ACTIONS.DELETE && onDelete) {
                onDelete(activeId);
              }
              handleClose();
            }}
            variant="outlined"
          >
            {action}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

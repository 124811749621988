import React from 'react';
import Typography from '@material-ui/core/Typography';
import { APP_NAME } from '../../utils/constants';

const Footer = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{`Copyright © ${APP_NAME} ${new Date().getFullYear()}`}
		</Typography>
	);
}

export default Footer;

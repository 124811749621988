import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

const UseToast = () => {
  const { addToast } = useToasts();
  const toast = useCallback(
    (content, appearance = 'info') => {
      addToast(content, {
        appearance,
        autoDismiss: true,
      })
    },
    [addToast],
  )
  return {
    toast,
    success: content => toast(content, 'success'),
    error: content => toast(content, 'error'),
    warning: content => toast(content, 'warning'),
    info: content => toast(content, 'info'),
  }
}

export default UseToast;

import React from 'react';
import { Formik, Form } from 'formik';

const CustomFormik = ({ children, className, ...othetProps }) => {
  return (
    <Formik {...othetProps}>
      {formik => <Form onSubmit={formik.handleSubmit} className={className}>
        {
          typeof children === "function" ? children(formik) : children
        }
      </Form>}
    </Formik>
  )
}

export default CustomFormik;

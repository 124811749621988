import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import ButtonSubmit from '../../components/ButtonSubmit';
import CustomFormik from '../../components/FormControls/CustomFormik';
import TextField from '../../components/FormControls/TextField';
import { useRouter } from '../../components/hooks/useRouter';
import SignInSignUpLayout from '../../components/Layouts/signInSignUpLayout';
import { urlPaths } from '../../routes';
import { useAuth } from '../../useAuth';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(1),
	},
}));

const Register = () => {
	const classes = useStyles();
	const { loggingIn, signUp, isAuthenticated } = useAuth();
	const { push } = useRouter();

	useEffect(() => {
		if (isAuthenticated) {
			push(urlPaths.dashboard)
		}
	}, [isAuthenticated, push])

	return <SignInSignUpLayout>
		<CustomFormik onSubmit={async ({ email, password }) => {
			await signUp(email, password);
		}} initialValues={{ email: '', password: '', confirmPassword: '' }}>
			<>
				<TextField
					label="Email Address"
					name="email"
					autoComplete="email"
					required
				/>
				<TextField
					name="password"
					label="Password"
					type="password"
					autoComplete="current-password"
					required
				/>
				<TextField
					name="confirmPassword"
					label="Retype Password"
					type="password"
					autoComplete="current-password"
					required
				/>

				<Grid container spacing={1} direction="column" alignItems="center">
					<Grid item xs>
						<ButtonSubmit
							loading={loggingIn}
							className={classes.submit}
						>
							Register
          </ButtonSubmit>
					</Grid>
					<Grid item xs>
						<Link href="#" variant="body2">
							Forgot password?
              </Link>
					</Grid>
					<Grid item>
						<Link onClick={() => push(urlPaths.login)} variant="body2">
							{"Have an account? Sign In"}
						</Link>
					</Grid>
				</Grid>
			</>
		</CustomFormik>
	</SignInSignUpLayout>
}

export default Register;

import React from "react";
import _get from "lodash/get";
import _map from "lodash/map";
import { connect, Field } from "formik";
import {
  TextField,
  FormGroup,
  FormLabel,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/InfoOutlined";
import classNames from "classnames";

const CustomTextField = ({
  name,
  label,
  labelIsInside = false,
  infoText,
  select,
  formik,
  variant = "outlined",
  multiline = false,
  className,
  style,
  autoComplete,
  ...otherProps
}) => {
  const touched = _get(formik, `touched.${name}`, false);
  const error = _get(formik, `errors.${name}`, "");
  const errorMessage = touched && error ? error : "";

  return (
    <FormGroup row className={classNames("mb-3", className)} style={style}>
      <div className="d-flex w-100">
        {!labelIsInside && <FormLabel style={{ flex: 1 }}>{label}</FormLabel>}
        {infoText && (
          <Tooltip title={infoText}>
            <LogoutIcon style={{ fontSize: "0.75rem" }} />
          </Tooltip>
        )}
      </div>
      {!!select ? (
        <Field
          select
          name={name}
          as={TextField}
          variant={variant}
          label={labelIsInside ? label : null}
          fullWidth
          margin="dense"
          error={!!errorMessage}
          helperText={errorMessage}
          inputProps={{ autoComplete }}
          {...otherProps}
        >
          {_map(select, (option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      ) : (
        <Field
          name={name}
          as={TextField}
          variant={variant}
          label={labelIsInside ? label : null}
          fullWidth
          margin="dense"
          multiline={multiline}
          error={!!errorMessage}
          helperText={errorMessage}
          inputProps={{ autoComplete }}
          {...otherProps}
        />
      )}
    </FormGroup>
  );
};

export default connect(CustomTextField);

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import _includes from "lodash/includes";
import { snackbarError } from "./utils/snackbar";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const uri = () => {
  if (_includes(BACKEND_URL, "/graphql")) {
    return BACKEND_URL;
  }
  return `${BACKEND_URL}/graphql`;
};

const httpLink = new HttpLink({ uri });

const useLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      return snackbarError(message);
    });

  if (networkError) snackbarError(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorization = JSON.parse(localStorage.getItem("jwt")) || "";
  operation.setContext({
    headers: {
      authorization,
    },
  });

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: ApolloLink.from([useLink, authMiddleware, httpLink]),
});
